<template>
  <b-row>
    <template v-for="colKey in columns">
      <b-col v-if="$scopedSlots[colKey]" :key="colKey" :cols="columnAttributes[colKey].cols">
        <slot :name="colKey" />
      </b-col>
    </template>
  </b-row>
</template>

<script>
import { MAX_COLUMNS_COUNT } from '@/assets/js/config/client'

const COLUMN_NAMES = Array.from(new Array(MAX_COLUMNS_COUNT), (e, i) => `col-${i + 1}`)

export default {
  name: 'Columns',
  props: {
    spotlight: {
      type: String,
      default: 'none',
      validator: value => ['none'].concat(COLUMN_NAMES).includes(value)
    }
  },
  data () {
    return {
      maxColumns: this.$root.scss.grid.columns.value,
      columns: COLUMN_NAMES
    }
  },
  computed: {
    usedSlotsCount () {
      const slotKeys = Object.keys(this.$slots)
      return this.columns.filter(cKey => slotKeys.includes(cKey)).length
    },
    columnAttributes () {
      return this.columns
        .reduce((cols, cKey) => {
          const hasSpotlight = this.spotlight === cKey
          const spotlightWidth = Math.min(Math.floor(this.maxColumns / this.usedSlotsCount + 2), this.maxColumns)

          return Object.assign(cols, {
            [cKey]: {
              cols: hasSpotlight ? `${this.$root.mobile.down.key}-${spotlightWidth}` : this.$root.mobile.down.key
            }
          })
        }, {})
    }
  }
}
</script>

<style lang="scss"></style>
